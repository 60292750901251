module.exports = {
  siteTitle:
    'PrintAWorld',
  siteTitleAlt: 'PrintAWorld | NYC Fast 3D Printing and Fabrication Company', // This allows an alternative site title for SEO schema.
  publisher: 'Publisher named PrintAWorld', // Organization name used for SEO schema
  siteDescription:
    'PrintAWorld is a 3D Printing Company in New York. We specialize in 3D Modeling, 3D Scanning, Laser Cutting & 3D Printing. Call us at (929) 213 9604.',
  siteUrl: 'https://prtwd.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: 'PrintAWorld', // Author for RSS author segment and SEO schema
  authorUrl: 'https://prtwd.com/about/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@PrintAworld3D', // Change for Twitter Cards
  shortTitle: 'PrintAWorld', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/images/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/images/company-logo.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: 'Copyright © 2018 PrintAWorld', // Copyright string for the RSS feed
}
