import { space, fontSizes, mediaQueries, colors } from '../utils/tokens'

const theme = {
  space,
  fontSizes,
  mediaQueries,
  colors,
  sizes: {
    maxWidth: '1200px',
    maxWidthCentered: '900px',
    maxWidthCenteredMobile: '300px',
  },
  gradients: {
    primary: `linear-gradient(225deg, rgba(245,166,35,0.30) 0%, #001839 100%)`,
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
  dropShadow: '0 2px 26px rgba(0, 0, 0, 0.16)',
  header: {
    boxShadow:
      '0 6px 8px -6px rgba(0,0,0,0.12), 0 12px 24px 2px rgba(0,0,0,0.08)',
  },
}

export default theme
