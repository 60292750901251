export const space = [
  0, 4, 8, 16, 32, 64, 128, 256, 512
]

export const fontSizes = [`0.72rem`, `0.83rem`, `1rem`, `1.333rem`, `2rem`, `2.66rem`, `4.667rem`]


export const fontFamily = {
         body: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
  Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;`,
         heading: `'Avenir Next','Helvetica Neue','Segoe UI','Helvetica','Arial',sans-serif`,
       }

export const breakpoints = [
  `320px`,
  `550px`,
  `750px`,
  `1000px`,
  `1200px`,
  `1600px`,
  `1920px`,
  `2650px`,
]

export const mainOffset = `128px`

export const boxHeights = {
  instantQuoteBottom: `104px`,
  plainHeader: `80px`,
  banner: `48px`,
}

export const fixedHeights = {
  header: `80px`,
  banner: `48px`,
}

export const lineHeights = {
  body: `1.5`,
  heading: `1`,
  subHeading: `1`,
}

export const zIndices = {
  sideBar: 998,
  banner: 997,
  submitFormModal: 1000,
}


export const palette = {
  orange: {
    90: `#7B341E`,
    80: `#9C4221`,
    70: `#C05621`,
    60: `#DD6B20`,
    50: `#ED8936`,
    40: `#F6AD55`,
    30: `#FBD38D`,
    20: `#FEEBC8`,
    10: `#FFFAF0`,
  },
  grey: {
    90: `#1A202C`,
    80: `#2D3748`,
    70: `#4A5568`,
    60: `#718096`,
    50: `#A0AEC0`,
    40: `#CBD5E0`,
    30: `#E2E8F0`,
    20: `#EDF2F7`,
    10: `#F7FAFC`,
  },
  blue: {
    90: `#2A4365`,
    80: `#2C5282`,
    70: `#2B6CB0`,
    60: `#3182CE`,
    50: `#4299E1`,
    40: `#63B3ED`,
    30: `#90CDF4`,
    20: `#BEE3F8`,
    10: `#EBF8FF`,
  },
  green: {
    90: `#22543D`,
    80: `#276749`,
    70: `#2F855A`,
    60: `#38A169`,
    50: `#48BB78`,
    40: `#68D391`,
    30: `#9AE6B4`,
    20: `#C6F6D5`,
    10: `#F0FFF4`,
  },
  red: {
    90: `#742A2A`,
    80: `#9B2C2C`,
    70: `#C53030`,
    60: `#E53E3E`,
    50: `#F56565`,
    40: `#FC8181`,
    30: `#FEB2B2`,
    20: `#FED7D7`,
    10: `#FFF5F5`,
  },
  indigo: {
    90: `#3C366B`,
    80: `#434190`,
    70: `#4C51BF`,
    60: `#5A67D8`,
    50: `#667EEA`,
    40: `#7F9CF5`,
    30: `#A3BFFA`,
    20: `#C3DAFE`,
    10: `#EBF4FF`,
  },
}

export const colors = {
  base: '#061426', // Black
  secondary: '#bfbfbf', // Medium Gray
  tertiary: '#EFEFEF', // Light Gray
  highlight: '#5b8bf7', // Light Blue
  primaryBrand: palette.orange[50],
  black: `#000000`,
  white: '#ffffff',
  gray: '#bfbfbf',
  lightGray: palette.grey[10],
  lightBase: '#002559',
  darkGray: palette.grey[80],
  danger: `#EE4266`,
  success: `#309E7A`,
  mute: palette.grey[10],
}

export const boxShadows = {
  card: `0 5px 16px rgba(0,0,0,0.1)`,
  bottomNav: `0 -16px 16px -16px ${colors.gray}`,
}

export const gradient = {
  primary: `linear-gradient(-225deg, rgba(245,166,35,0.26) 0%, rgba(0,24,57,0.81) 100%);`
}

export const letterSpacings = {
  extraTight: `-2.66px`,
  tight: `-1.77px`,
  normal: `0.64px`,
}

breakpoints.xs = breakpoints[0]
breakpoints.sm = breakpoints[1]
breakpoints.md = breakpoints[2]
breakpoints.lg = breakpoints[3]
breakpoints.xl = breakpoints[4]
breakpoints.xxl = breakpoints[5]
breakpoints.xxxl = breakpoints[6]
breakpoints.xxxxl = breakpoints[7]

let mediaQueries = {}
for (let breakpoint in breakpoints) {
  mediaQueries[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]})`
}

export { 
  mediaQueries,
}